"use strict";

require('../css/normalize.css');
require('../css/holtmaat.css');

document.addEventListener("DOMContentLoaded",function()
{
  //alert("DOMContentLoaded");
}); //DOMContentLoaded

